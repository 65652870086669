// script interface
import "../components/common_componentc/normalize/index";
import "../components/interface/grid/index";
import "../components/interface/form/index";
import "../components/interface/button/index";

// script common elements
import "../components/module/modal_script/index";
import "../components/module/bread_crumbs/index";
// import '../components/module/catalog_elements/index'

// script pages
import "../components/common_componentc/header/index";
import "../components/common_componentc/footer/index";
import "../components/common_componentc/form_action/index";

import "../components/pages/other/index";
